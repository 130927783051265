import React, {CSSProperties, MouseEventHandler, PropsWithChildren, useRef} from 'react';

import {
    AnalyseStoryFlowsEconomy,
    MemoriesStorySummary,
    MemoryEconomyStats,
    TotalMemoryEconomy
} from '../types/AnalyseStoryFlowsEconomyManager';

type MinMaxPointsPropsSingleStory = {
    episodeData: TotalMemoryEconomy;
    affectionPointsList: string[];
}
type MinMaxPointsPropsSummary = {
    memoriesStorySummary: MemoriesStorySummary;
    affectionPointsList: string[];
}
type MinMaxPointsProps = {
    memoriesEconomyStats: Record<number, TotalMemoryEconomy>;
    memoriesStorySummary: MemoriesStorySummary;
    affectionPointsList: string[];
}

const cellStyle: CSSProperties = {
    border: '1px solid gray',
    padding: '4px',
    textAlign: 'center',
};

const Cell = ({children, header, ...rest}: PropsWithChildren & {onClick?: MouseEventHandler} & { header?: boolean }) => (
    header ? (
        <th style={{...cellStyle, textAlign: 'left'}} {...rest}>{children}</th>
    ) : (
        <td style={{...cellStyle, cursor: rest.onClick ? 'pointer' : ''}} {...rest}>{children}</td>
    )
);

const PropertyRow = ({title, property, memoryNames, data, onCellClick, affectionPointsList}: {
    title: string,
    property: keyof MemoryEconomyStats,
    memoryNames: string[],
    data: MemoriesStorySummary,
    onCellClick?: (memory: string, property: keyof MemoryEconomyStats) => void,
    affectionPointsList: string[],
}) => (
    <tr>
        <Cell header>{title}</Cell>
        {affectionPointsList.map((memory) => (
            <Cell
                key={memory}
                onClick={() => onCellClick?.(memory, property)}
            >
                {
                    memoryNames.includes(memory) ? (
                        data[memory][property] === 1000000 ? 'N/A' : data[memory][property]
                    ) : ''
                }
            </Cell>
        ))}
    </tr>
);

const propToField: [string, keyof MemoryEconomyStats][] = [
    ['MAX FREE POINTS', 'freeModeMaxPoints'],
    ['MAX PREMIUM POINTS', 'paidModeMaxPoints'],
    ['MIN POINTS', 'minPoints'],

    ['MIN - DECREASE GRANTS', 'minDecreaseGrants'],
    ['MIN - DECREASE POINTS', 'minDecreasePoints'],

    ['MAX FREE - TOTAL GRANTS', 'freeModeMaxGrants'],
    ['MAX PREMIUM - TOTAL GRANTS', 'paidModeMaxGrants'],
    ['MIN - TOTAL GRANTS', 'minTotalGrants'],

    ['MAX PREMIUM - FREE GRANTS', 'paidModeMaxFreeGrants'],
    ['MIN - FREE GRANTS', 'freeModeMinGrants'],

    ['MAX PREMIUM - PREMIUM GRANTS', 'paidModeMaxPaidGrants'],
    ['MAX PREMIUM - FREE NESTED GRANTS', 'paidModeMaxFreeNestedGrants'],

    ['MAX FREE ROUTE - CHECKS', 'maxFreeModeMaxChecks'],
    ['MAX PREMIUM ROUTE - CHECKS', 'paidModeMaxChecks'],

    ['MIN CHECK POINT AMOUNT', 'minChecks'],
    ['MAX CHECK POINT AMOUNT', 'maxChecks'],
];

const flowToBranchNames = (flow: AnalyseStoryFlowsEconomy) => flow.flows.map(branch => branch.name);

const MinMaxPointsSingleStory = (props: MinMaxPointsPropsSingleStory) => {
    const {stats, flows} = props.episodeData;
    const {affectionPointsList} = props;
    const memoryNames = Object.keys(stats).sort();

    const handleCellClick = (memory: string, property: keyof MemoryEconomyStats) => {
        const memoryPropertyFlows = flows[memory]?.[property];
        if (memoryPropertyFlows !== undefined) {
            const branches = flowToBranchNames(memoryPropertyFlows[0]).join('\n');
            console.log(`[${property}] flows for ${memory}`);
            console.log(branches);
        }
    };

    return (
        <div>
            <table style={{
                borderCollapse: 'collapse',
                border: '1px solid black',
            }}>
                <thead>
                <tr>
                    <Cell header>&nbsp;</Cell>
                    {affectionPointsList.map((memory) => (
                        <Cell header key={memory}>{memory}</Cell>
                    ))}
                </tr>
                </thead>
                <tbody>
                {propToField.map(([title, property]) => (
                    <PropertyRow
                        key={title}
                        title={title}
                        property={property}
                        memoryNames={memoryNames}
                        affectionPointsList={affectionPointsList}
                        data={stats}
                        onCellClick={handleCellClick}
                    />
                ))}
                </tbody>
            </table>
        </div>
    );
};

const MinMaxPointsSummary = ({memoriesStorySummary, affectionPointsList}: MinMaxPointsPropsSummary) => {
    const memoryNames = Object.keys(memoriesStorySummary).sort();
    const existingProps = new Set(
        Object.values(memoriesStorySummary)
            .map(p => Object.keys(p))
            .flat()
    );
    const showOnlyProps = propToField.filter(([_, property]) => existingProps.has(property));

    return (
        <div>
            <table style={{
                borderCollapse: 'collapse',
                border: '1px solid black',
            }}>
                <thead>
                <tr>
                    <Cell header>&nbsp;</Cell>
                    {affectionPointsList.map((memory) => (
                        <Cell header key={memory}>{memory}</Cell>
                    ))}
                </tr>
                </thead>
                <tbody>
                {showOnlyProps.map(([title, property]) => (
                    <PropertyRow
                        key={title}
                        title={title}
                        property={property}
                        memoryNames={memoryNames}
                        affectionPointsList={affectionPointsList}
                        data={memoriesStorySummary}
                    />
                ))}
                </tbody>
            </table>
        </div>
    );
};

export const MinMaxPoints = ({memoriesEconomyStats, memoriesStorySummary, affectionPointsList}: MinMaxPointsProps) => {
    const sortedMemories = [...affectionPointsList].sort();
    const rootRef = useRef<HTMLDivElement | null>(null);

    const handleSelectAll = () => {
        if (!rootRef.current) {
            return;
        }

        const range = document.createRange();
        range.selectNodeContents(rootRef.current);

        document.getSelection()?.removeAllRanges();
        document.getSelection()?.addRange(range);
    };

    return (
        <div>
            <button onClick={handleSelectAll}>select all</button>
            <div ref={rootRef}>
                {Object.entries(memoriesEconomyStats).map(([chapter, chapterData]) => (
                    <div key={chapter}>
                        <div style={{fontWeight: 'bold', fontSize: '1.5em'}}>Episode {chapter}</div>
                        <MinMaxPointsSingleStory
                            episodeData={chapterData}
                            affectionPointsList={sortedMemories}
                        />
                    </div>
                ))}
                <h4>Story summary</h4>
                <MinMaxPointsSummary
                    memoriesStorySummary={memoriesStorySummary}
                    affectionPointsList={sortedMemories}
                />
            </div>
        </div>
    );
};
