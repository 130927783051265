import { minBy, sortBy } from 'lodash-es';
import { BookEconomy, BranchesEconomy, StoryEconomy } from './bookEconomy';

// TODO: Make types for properties
export class BookEconomyStoryManager {
  private _book: BookEconomy;
  private _stories: StoryEconomy[] = [];
  private _branches: BranchesEconomy | undefined = undefined;

  constructor(exportedBook: BookEconomy) {
    this._book = {
      ...exportedBook,
      stories: exportedBook.stories.map((story) => ({
        ...story,
        contents: {
          branches: Object.entries(story.contents.branches).reduce((acc, [key, value]) => ({
            ...acc, [key]: { ...value, name: key },
          }), {}),
        },
      })),
    };
    // console.log('book', this._book);
    this._stories = sortBy(this._book.stories, (story) => story.chapter);
    this.goFirstStory();
  }

  public getBranchesByStoryId(storyId: number): BranchesEconomy | undefined {
    const currentStory = this._stories.find((story) => story.id === storyId);
    if (!currentStory) {
      return undefined;
    }
    return currentStory.contents.branches;
  }

  public getVariables() {
    return this._book.variables;
  }

  public goFirstStory(): void {
    const currentStory = minBy(this._stories, (story) => story.chapter);
    this._branches = currentStory?.contents.branches;
  }

  public get stories(): StoryEconomy[] {
    return this._stories;
  }
}
