import React, { useState } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { StoryState } from '../../../../dorian-shared/types/story/Story';
import { MemoryDTO } from '../../Book/MemoryBank/memoryBankTypes';

export type BookEconomySettingsProps = {
  memoryBank: MemoryDTO[],
  onLoad: (storyType: StoryState) => void,
}

export function BookEconomySettings(props: BookEconomySettingsProps) {
  const { onLoad, memoryBank } = props;
  const [storyState, setStoryState] = useState<StoryState>(StoryState.Active);

  return (
    <Form.Group as={Row}>
      <Form.Label as="legend" column sm={2}>
        Select story state type
      </Form.Label>
      <Col sm="auto">
        <Form.Check
          type="radio"
          label="Live"
          id="storyStateLive"
          checked={storyState === StoryState.Live}
          onChange={(e) => setStoryState(StoryState.Live)}
        />
        <Form.Check
          type="radio"
          label="Active"
          id="storyStateActive"
          checked={storyState === StoryState.Active}
          onChange={(e) => setStoryState(StoryState.Active)}
        />
      </Col>
      <Col>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => onLoad(storyState)}
          disabled={!storyState}
        >
          Load
        </Button>
      </Col>
    </Form.Group>
  );
}
