import React from 'react';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from './BadgeWithTooltip.module.scss';

interface BadgeWithTooltipProps {
    id: string,
    title: string,
    content: string,
    showDelay?: number,
    hideDelay?: number,
}

export function BadgeWithTooltip(props: BadgeWithTooltipProps) {
  const {
    id, title, content, showDelay, hideDelay,
  } = props;

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: showDelay ?? 250, hide: hideDelay ?? 400 }}
      overlay={(
        <Popover
          id={id}
          style={{ zIndex: 100000 }}
        >
          <Popover.Title as="h3">
            {title}
          </Popover.Title>
          <Popover.Content>
            <p>
              {content}
            </p>
          </Popover.Content>
        </Popover>
        )}
    >
      <div className={classNames.badge} data-testid="badgeContainer">
        <Badge
          pill
          variant="secondary"
        >
          i
        </Badge>
      </div>
    </OverlayTrigger>
  );
}
