import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  MemoryDTO, MemoryShowIn, MemoryType, MemoryValue,
} from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { Character } from 'dorian-shared/types/character/Character';
import { BranchStepMemoryActionType } from '../../../../../dorian-shared/types/branch/BranchStep';
import { getMemoryShowInByView, getMemoryViewValue } from '../../../Book/MemoryBank/memoryBankUtils';
import { MemoryBankCheckValue } from './MemorySlotCompareForm';
import { MemorySlotCompareValueFormList } from './MemorySlotCompareValueFormList';

type MemorySlotCompareValueFormProps = {
  valueInputValue: MemoryValue | MemoryShowIn[],
  stepIndex: number,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  characters: Character[],
  memoryBankSlot?: MemoryDTO,
  inputName: string,
  isDisabled?: boolean,
  actionType?: BranchStepMemoryActionType | '',
}

export function MemorySlotCompareValueForm(props: MemorySlotCompareValueFormProps) {
  const {
    valueInputValue, stepIndex, onChangeValue, memoryBankSlot, characters, inputName,
    isDisabled, actionType,
  } = props;

  const isViewValue = actionType === BranchStepMemoryActionType.View;
  const isStringValue = memoryBankSlot?.type === MemoryType.String || memoryBankSlot?.type === MemoryType.Number;
  const isTextValue = isStringValue && !isViewValue;
  const isBooleanValue = memoryBankSlot?.type === MemoryType.Boolean;
  const isCharacterValue = memoryBankSlot?.type === MemoryType.Character;

  return (
    <Form.Group as={Row}>
      <Col>
        {isTextValue && (
          <Form.Control
            disabled={isDisabled}
            size="sm"
            type="text"
            placeholder="value"
            name={inputName}
            value={valueInputValue}
            onChange={(event) => {
              onChangeValue([{
                name: 'value',
                value: event.target.value,
              }], stepIndex);
            }}
          />
        )}

        {isBooleanValue && (
          <MemorySlotCompareValueFormList
            isDisabled={isDisabled}
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            valueInputValue={valueInputValue as MemoryValue}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </MemorySlotCompareValueFormList>
        )}

        {isCharacterValue && (
          <MemorySlotCompareValueFormList
            isDisabled={isDisabled}
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            valueInputValue={valueInputValue as MemoryValue}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            {characters.map((character) => (
              <option value={character.id} key={character.id}>
                {character.name}
              </option>
            ))}
          </MemorySlotCompareValueFormList>
        )}

        {isViewValue && (
          <MemorySlotCompareValueFormList
            isDisabled={isDisabled}
            stepIndex={stepIndex}
            onChangeValue={(values, index) => {
              const newValues = [...values];
              newValues[0].value = getMemoryShowInByView(true, values[0].value as MemoryShowIn);
              onChangeValue(newValues, index);
            }}
            valueInputValue={getMemoryViewValue(valueInputValue as MemoryShowIn[])}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            <option value={MemoryShowIn.Hide} title="Don't show anywhere">Hide</option>
            <option value={MemoryShowIn.Alert} title="Popup alert when changed">Alert</option>
            <option value={MemoryShowIn.Panel} title="Popup alert & My Journey Panel">Panel</option>
            <option value={MemoryShowIn.Hud} title="Popup alert & My Journey Panel & Icon in Story">Panel & Story</option>
          </MemorySlotCompareValueFormList>
        )}
      </Col>
    </Form.Group>
  );
}
