import React, {useState} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {EventKey} from 'react-bootstrap/esm/types';

import {MinMaxPoints} from './MinMaxPoints/MinMaxPoints';
import {MemoriesStorySummary, TotalMemoryEconomy} from './types/AnalyseStoryFlowsEconomyManager';

enum BookEconomyTab {
  MinMaxPoints = 'minMaxPoints',
  Choices = 'Choices',
}

type BookEconomyTabsProps = {
  bookId: string;
  memoriesEconomyStats: Record<number, TotalMemoryEconomy> | null;
  memoriesStorySummary: MemoriesStorySummary | null;
  affectionPointsList: string[] | null;
}

export function BookEconomyTabs(props: BookEconomyTabsProps) {
  const {bookId, memoriesEconomyStats, memoriesStorySummary, affectionPointsList} = props;
  const [key, setKey] = useState<EventKey>(BookEconomyTab.MinMaxPoints);

  return (
    <Tabs
      id="book-economy-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k ?? BookEconomyTab.MinMaxPoints)}
    >
      <Tab eventKey={BookEconomyTab.MinMaxPoints} title="Min/Max points">
        {memoriesEconomyStats && memoriesStorySummary && affectionPointsList && (
            <MinMaxPoints
                memoriesEconomyStats={memoriesEconomyStats}
                memoriesStorySummary={memoriesStorySummary}
                affectionPointsList={affectionPointsList}
            />
        )}
      </Tab>
      <Tab eventKey={BookEconomyTab.Choices} title="Choices" disabled>
        {`TAB WITH Choices for ${bookId}`}
      </Tab>
    </Tabs>
  );
}
