import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import { useApiService } from 'contexts/ApiServiceContext/ApiServiceContext';
import { useMemoryBankServiceContext } from '../../../contexts/MemoryBankContext/MemoryBankServiceContext';
import { StoryState } from '../../../dorian-shared/types/story/Story';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';
import { logger } from '../../../services/loggerService/loggerService';
import { PageWrapper } from '../../ui/PageWrapper';
import { showToast } from '../../ui/utils';
import { MemoryDTO, MemoryType } from '../Book/MemoryBank/memoryBankTypes';
import { BookEconomyFileLoader } from './BookEconomyFileLoader/BookEconomyFileLoader';
import { BookEconomySettings } from './BookEconomySettings/BookEconomySettings';
import { BookEconomyTabs } from './BookEconomyTabs';
import {
  AnalyseStoryFlowsEconomy,
  AnalyseStoryFlowsEconomyManager, MemoriesStorySummary, MemoryStorySummary,
  TotalMemoryEconomy
} from './types/AnalyseStoryFlowsEconomyManager';
import {
  BookEconomy,
  BranchEconomy,
  BranchesEconomy,
  MemoryBank,
  StepActionEconomy,
  StepTypeEconomy,
  StepSwitchEconomy, StepCheckEconomy
} from './types/bookEconomy';
import { BookEconomyStoryManager } from './types/BookEconomyStoryManager';
import { getBookEconomyPage } from './utils';

const USE_CHECK_IN_SWITCHES = true;

const evaluateCheck = (memoryBank: MemoryBank, check: StepCheckEconomy) => {
  switch (check.operator) {
    case "equal":
      return memoryBank?.[check.variable] === check.value;
    case "notEqual":
      return memoryBank?.[check.variable] !== check.value;
    case "atLeast":
      return memoryBank?.[check.variable] >= check.value;
    case "atMost":
      return memoryBank?.[check.variable] <= check.value;
    case "greater":
      return memoryBank?.[check.variable] > check.value;
    case "less":
      return memoryBank?.[check.variable] < check.value;
    default:
      console.error(check);
      throw new Error(`unknown operator: ${check.operator}`);
  }
};

const selectBranchFromSwitches = (memoryBank: MemoryBank, check: StepCheckEconomy, switches: StepSwitchEconomy[]) => {
  const calcValue = evaluateCheck(memoryBank, check);

  for (const stepSwitch of switches) {
    const {value, goto} = stepSwitch;
    if (calcValue === value) {
      return goto.branch;
    }
  }

  return null;
};
const applyMemoryAction = (memoryBank: MemoryBank, action: StepActionEconomy) => {
  const {variable, type, value} = action;

  if (memoryBank?.[variable] === undefined) {
    return;
  }

  let memValue = memoryBank[variable];

  switch (type) {
    case 'increase':
      if (typeof memValue === 'number' && typeof value === 'number') {
        memValue += value;
      }
      break;
    case 'decrease':
      if (typeof memValue === 'number' && typeof value === 'number') {
        memValue -= value;
      }
      break;
    case 'set':
      memValue = value;
      break;
  }

  memoryBank[variable] = memValue;
};

const goPlay = (
    storyFlowsManager: AnalyseStoryFlowsEconomyManager,
    isPaid: boolean,
    currentBranch: BranchEconomy,
    branches: BranchesEconomy,
    flow: BranchEconomy[],
    affectionPointsMemories: string[],
    memoryBank: MemoryBank
) => {
  // console.log('Current node:', startBranch.title);
  if (storyFlowsManager.storyFlows.length > 1000000) {
    logger.debug('Too many story flows. Breaking...');
    return;
  }

  if (flow.some((branch) => branch.id === currentBranch.id)) {
    logger.info(`Branch ${currentBranch.name} is already in stack. Breaking flow...`);
    return;
  }

  flow.push(currentBranch);

  const currentStep = currentBranch.steps[0];
  if (!currentStep) {
    logger.log('No current step. Breaking flow...');
    return;
  }
  const currentStepType = currentStep.type;

  switch (currentStepType) {
    case StepTypeEconomy.Ending:
      storyFlowsManager.addStoryFlow(flow, false, isPaid, affectionPointsMemories, memoryBank);
      return;
    case StepTypeEconomy.Choice: {
      const currentAnswers = currentStep.answers;
      if (!currentAnswers) {
        logger.log('No answers found. Breaking flow...');
        return;
      }
      for (const answer of currentAnswers) {
        // answer requirement
        const requirement = answer.requirement;
        if (requirement?.check) {
          if (!evaluateCheck(memoryBank, requirement.check)) {
            continue;
          }
        }
        const branchName = answer.goto?.branch;
        const paidChoice = AnalyseStoryFlowsEconomyManager.isAnswerPaid(answer.type);
        if (branchName) {
          goPlay(storyFlowsManager, isPaid || paidChoice, branches[branchName], branches, [...flow], affectionPointsMemories, {...memoryBank});
        }
      }
      return;
    }
    case StepTypeEconomy.Check: {
      if (USE_CHECK_IN_SWITCHES) {
        const {switch: currentSwitches, check} = currentStep;
        if (!currentSwitches || !check) {
          logger.log('No switches/checks found. Breaking flow...');
          return;
        }

        const branchName = selectBranchFromSwitches(memoryBank, check, currentSwitches);
        if (branchName !== null && branches[branchName]) {
          goPlay(storyFlowsManager, isPaid, branches[branchName], branches, [...flow], affectionPointsMemories, {...memoryBank});
        } else {
          logger.log(`Branch ${branchName} not found for switch in branch ${currentBranch.name}`);
        }
      } else {
        // old logic
        const currentSwitches = currentStep.switch;
        if (!currentSwitches) {
          logger.log('No switches found. Breaking flow...');
          return;
        }
        for (let i = 0; i < currentSwitches.length; i++) {
          const stepSwitch = currentSwitches[i];
          const branchName = stepSwitch.goto.branch;
          if (branchName) {
            goPlay(storyFlowsManager, isPaid, branches[branchName], branches, [...flow], affectionPointsMemories, {...memoryBank});
          } else {
            logger.log(`Branch ${branchName} not found for switch ${i + 1} in branch ${currentBranch.name}`);
          }
        }
      }
      return;
    }
    default:
      break;
  }

  for (const step of currentBranch.steps) {
    switch (step.type) {
      case StepTypeEconomy.Dialogue:
      case StepTypeEconomy.Narrator:
      case StepTypeEconomy.Texting:
      case StepTypeEconomy.Thinking:
      case StepTypeEconomy.Reaction:
        break;
      case StepTypeEconomy.Remember:
        if (step.action) {
          applyMemoryAction(memoryBank, step.action);
        }
        break;

      default:
        logger.debug(`Unknown step type: ${step.type}`);
        break;
    }
  }
  const nextBranch = currentBranch.steps[currentBranch.steps.length - 1].goto?.branch;
  if (!nextBranch) {
    logger.error('No next branch found. Breaking flow...');
    return;
  }
  goPlay(storyFlowsManager, isPaid, branches[nextBranch], branches, flow, affectionPointsMemories, {...memoryBank});
};

const copySelectedProps = (memoryBank: MemoryBank, props: string[]) => {
  const result: MemoryBank = {};
  for (const prop of props) {
    result[prop] = memoryBank[prop];
  }
  return result;
};

export function BookEconomyPage() {
  const [bookManager, setBookManager] = useState<BookEconomyStoryManager | null>(null);
  const [memoryBank, setMemoryBank] = useState<MemoryDTO[]>([]);
  const [memoriesEconomyStats, setMemoriesEconomyStats] = useState<Record<number, TotalMemoryEconomy> | null>(null);
  const [memoriesStorySummary, setMemoriesStorySummary] = useState<MemoriesStorySummary | null>(null);
  const [affectionPointsList, setAffectionPointsList] = useState<string[] | null>(null);
  const { id: bookId } = useParams<{ id: string }>();

  const apiService = useApiService();
  const memoryBankService = useMemoryBankServiceContext();

  const economyPage = getBookEconomyPage(bookId);

  useEffect(() => {
    if (!bookManager) {
      return;
    }

    const extractData = async () => {
      const staticsPerStory: Record<number, TotalMemoryEconomy> = {};
      let totalCalcTime = 0;
      const calcResults: {name: string, flows: number, 'calc time': string}[] = [];
      const initialMemoryBank: MemoryBank = Object.fromEntries(
          Object.entries(bookManager.getVariables())
              .map(([name, data]) => [name, data.defaultValue])
      );
      let memoryBanks: MemoryBank[] = [initialMemoryBank];
      const affectionPointsMemories = Object.entries(bookManager.getVariables())
          .filter(([name, data]) => name.toLowerCase().includes('affection') || data.displayName.length > 0)
          .map(([name]) => name);
      const affectionPointsUsed = new Set<string>();

      for (const story of bookManager.stories) {
        const startTime = Date.now();
        const branches = bookManager.getBranchesByStoryId(story.id);
        if (!branches) {
          logger.error(`No branches found for story ${story.id}`);
          continue;
        }
        const storyFlowsManager = new AnalyseStoryFlowsEconomyManager();
        console.log(`===[ Start a new story flow from: ${story.title} ]===`);
        const startBranch = branches.intro;
        if (!startBranch) {
          logger.error('No intro branch found');
          continue;
        }

        const tempStoryFlows: AnalyseStoryFlowsEconomy[] = [];
        for (const memoryBank of memoryBanks) {
          goPlay(storyFlowsManager, false, startBranch, branches, [], affectionPointsMemories, memoryBank);
          tempStoryFlows.push(...storyFlowsManager.storyFlows);
          storyFlowsManager.storyFlows = [];
        }

        storyFlowsManager.storyFlows = tempStoryFlows;
        const memoriesEconomyStats = storyFlowsManager.calculateEconomyStats();
        const memoriesCriticalFlows = storyFlowsManager.getCriticalFlows(memoriesEconomyStats);
        staticsPerStory[story.chapter] = {
          stats: memoriesEconomyStats,
          flows: memoriesCriticalFlows,
        };

        // add APs which were used in this episode to the list of all APs used since ep.1
        for (const memory of Object.keys(memoriesEconomyStats)) {
          affectionPointsUsed.add(memory);
        }

        const newMemoryBanks: MemoryBank[] = [];

        for (const memory of affectionPointsUsed) {
          // building the maximum path for this affection point
          // console.log(`building memory bank focused on max %c${memory}`, 'color:#ff8080');
          const newMemoryBank: MemoryBank = {};
          // ensuring we have all memories included
          for (const [key, value] of Object.entries(initialMemoryBank)) {
            const apMemoryStats = memoriesEconomyStats[key];
            if (key === memory) {
              newMemoryBank[key] = Object.values(staticsPerStory)
                  .map(chapter => Math.max(
                      chapter.stats?.[memory]?.freeModeMaxPoints ?? 0,
                      chapter.stats?.[memory]?.paidModeMaxPoints ?? 0
                  ))
                  .reduce((acc, curr) => acc + curr, initialMemoryBank[memory] as number);
            } else {
              if (typeof initialMemoryBank[key] !== 'number') {
                // not a number
                newMemoryBank[key] = initialMemoryBank[key];
              } else {
                // get minimum value
                newMemoryBank[key] = Object.values(staticsPerStory)
                    .map(chapter => chapter.stats?.[memory]?.minPoints ?? 0)
                    .reduce((acc, curr) => acc + curr, initialMemoryBank[memory] as number);
              }
            }
          }
          // console.log('NEW MEMORY BANK', copySelectedProps(newMemoryBank, [...affectionPointsUsed]));
          newMemoryBanks.push(newMemoryBank);
        }
        memoryBanks = newMemoryBanks;

        const calcTime = Date.now() - startTime;
        calcResults.push({
          name: story.title,
          'flows': storyFlowsManager.storyFlows.length,
          'calc time': `${(calcTime / 1000).toFixed(2)}s`,
        });
        totalCalcTime += calcTime;
        await Promise.resolve();
      }

      const totalEpisodesSummary = Object.values(staticsPerStory)
          .map(episodeStats => episodeStats.stats)
          .reduce<MemoriesStorySummary>((acc, item) => {
            for (const [memory, memStats] of Object.entries(item)) {
              const accMemory: MemoryStorySummary = acc[memory] ?? {
                freeModeMaxPoints: 0,
                paidModeMaxPoints: 0,
                minPoints: 0,
              };

              accMemory.freeModeMaxPoints! += memStats.freeModeMaxPoints;
              accMemory.paidModeMaxPoints! += memStats.paidModeMaxPoints;
              accMemory.minPoints! += memStats.minPoints;
              acc[memory] = accMemory;
            }

            return acc;
          }, {});
      // console.log('episodes stats', totalEpisodesSummary);
      setMemoriesStorySummary(totalEpisodesSummary);
      setAffectionPointsList(affectionPointsMemories);

      calcResults.push({
        name: 'total',
        flows: 0,
        'calc time': `${(totalCalcTime / 1000).toFixed(2)}s`,
      });
      console.table(calcResults);

      setMemoriesEconomyStats(staticsPerStory);
    };

    extractData().then();
  }, [bookManager]);

  useEffect(() => {
    memoryBankService.fetchMemoryBank(bookId)
      .then((response) => {
        const memoryBankFiltered = response.filter((memory) => memory.type === MemoryType.Number);
        setMemoryBank(memoryBankFiltered);
      })
      .catch((error) => {
        showToast({ textMessage: 'Error loading memory bank' });
        if (error instanceof Error) {
          bugTracker().reportError(error);
        } else {
          bugTracker().reportError(new Error('Error loading memory bank'));
        }
      });
  }, [bookId, memoryBankService]);

  const handleLoad = async (storyState: StoryState) => {
    try {
      const response = await apiService.fetchBookEconomy(bookId, storyState);
      const newBookEconomyStoryManager = new BookEconomyStoryManager(response as BookEconomy);
      setBookManager(newBookEconomyStoryManager);
    } catch (error) {
      showToast({ textMessage: 'Error loading story episodes' });
      if (error instanceof Error) {
        bugTracker().reportError(error);
      } else {
        bugTracker().reportError(new Error('Error loading story episodes'));
      }
    }
  };

  const handleFileLoad = (bookEconomy: BookEconomy) => {
    try {
      const newBookEconomyStoryManager = new BookEconomyStoryManager(bookEconomy);
      setBookManager(newBookEconomyStoryManager);
    } catch (error) {
      showToast({ textMessage: 'Error loading story episodes' });
      if (error instanceof Error) {
        bugTracker().reportError(error);
      } else {
        bugTracker().reportError(new Error('Error loading story episodes'));
      }
    }
  };

  return (
    <PageWrapper
      page={economyPage}
    >
      <BookEconomySettings
        onLoad={handleLoad}
        memoryBank={memoryBank}
      />
      <BookEconomyFileLoader onLoad={handleFileLoad} />
      {bookManager && (
        <BookEconomyTabs
          memoriesEconomyStats={memoriesEconomyStats}
          memoriesStorySummary={memoriesStorySummary}
          affectionPointsList={affectionPointsList}
          bookId={bookId}
        />
      )}
    </PageWrapper>
  );
}
