import React from 'react';
import { ApiServiceContext } from 'contexts/ApiServiceContext/ApiServiceContext';
import { MemoryBankService } from '../../services/memoryBankService/MemoryBankService';
import { BookLocationsContext, BookLocationsContextProvider } from '../BookLocationsContext/BookLocationsContext';
import { CharacterExpressionsContext, CharacterExpressionsContextProvider } from '../CharacterExpressionsContext/CharacterExpressionsContext';
import { MemoryBankServiceContext } from '../MemoryBankContext/MemoryBankServiceContext';

type CombinedContextType = {
  MemoryBankService: MemoryBankService;
};

interface WithContextProps {
    context: CombinedContextType;
}

// Function that takes a React component and returns a new component with context props
export function StoryBranchesWithContext<T extends WithContextProps = WithContextProps>(
  Component: React.ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return function (props: Omit<T, keyof WithContextProps>) {
    return (
      <ApiServiceContext.Consumer>
        {(apiService) => (
          <MemoryBankServiceContext.Consumer>
            {(memoryBankService) => (
              <BookLocationsContextProvider>
                <BookLocationsContext.Consumer>
                  {(bookLocations) => (
                    <CharacterExpressionsContextProvider>
                      <CharacterExpressionsContext.Consumer>
                        {(characterExpressions) => (
                          <Component
                            {...(props as T)}
                            context={{
                              apiService,
                              memoryBankService,
                              characterExpressions,
                              bookLocations,
                            }}
                          />
                        )}
                      </CharacterExpressionsContext.Consumer>
                    </CharacterExpressionsContextProvider>
                  )}
                </BookLocationsContext.Consumer>
              </BookLocationsContextProvider>
            )}
          </MemoryBankServiceContext.Consumer>
        )}
      </ApiServiceContext.Consumer>
    );
  };
}
