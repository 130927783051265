import React from 'react';
import { ApiServiceContext } from 'contexts/ApiServiceContext/ApiServiceContext';
import { CharacterExpressionsContext, CharacterExpressionsContextProvider } from 'contexts/CharacterExpressionsContext/CharacterExpressionsContext';
import { ExpressionConfig } from '../../dorian-shared/types/character/ExpressionConfig';
import { MemoryBankServiceContext } from '../MemoryBankContext/MemoryBankServiceContext';

type CombinedContextType = {
  characterExpressions: ExpressionConfig[];
};

interface WithContextProps {
    context: CombinedContextType;
}

// Function that takes a React component and returns a new component with context props
export function BookWithContext<T extends WithContextProps = WithContextProps>(
  Component: React.ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return function (props: Omit<T, keyof WithContextProps>) {
    return (
      <ApiServiceContext.Consumer>
        {(apiService) => (
          <MemoryBankServiceContext.Consumer>
            {(memoryBankService) => (
              <CharacterExpressionsContextProvider>
                <CharacterExpressionsContext.Consumer>
                  {(characterExpressions) => (
                    <Component
                      {...(props as T)}
                      context={{
                        apiService,
                        memoryBankService,
                        characterExpressions,
                      }}
                    />
                  )}
                </CharacterExpressionsContext.Consumer>
              </CharacterExpressionsContextProvider>
            )}
          </MemoryBankServiceContext.Consumer>
        )}
      </ApiServiceContext.Consumer>
    );
  };
}
