import classNames from 'classnames/bind';
import React from 'react';
import { Button, Col, Dropdown } from 'react-bootstrap';
import { isMemoryBankEnabled } from 'components/utils/isMemoryBankEnabled';
import { DeleteStep } from './DeleteStep';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export function AnswerActionsBox(props) {
  const {
    restrictedEdit,
    deleteAnswer,
    answerData,
    showAnswerTags,
    answerIndex,
    showAnswerData,
    showAnswerModifiers,
    answeredDataShowed,
    answerModifiers,
    sm,
    lg,
    md,
    user,
    disabledSortableAction,
    xl,
    answerTags,
    stepIndex, activeStepsFunc,
    slotRequirement,
    showSlotRequirement,
    isSlotRequirementActive,
    isSlotRequirementVisible,
  } = props;

  const handleFocus = () => activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-action`);

  const index = answerIndex;
  return (
    <Col
      sm={sm || 5}
      lg={lg || 4}
      xl={xl || 4}
      md={md || 4}
      className="text-right pl-0"
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Button
        size="sm"
        variant="secondary"
        className={cs('mx-1')}
        onClick={() => {
          handleFocus();
          showAnswerModifiers(index);
        }}
        onFocus={() => {
          handleFocus();
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        Modifiers (
        {answerModifiers}
        )
      </Button>

      <Button
        size="sm"
        variant="secondary"
        className={cs('mx-1')}
        onClick={() => {
          handleFocus();
          showAnswerTags(index);
        }}
        onFocus={() => {
          handleFocus();
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        Tags (
        {answerTags}
        )
      </Button>

      <Dropdown
        className="mx-1 d-inline-block"
        onToggle={(e) => {
          handleFocus();
          disabledSortableAction(e);
        }}
      >
        <Dropdown.Toggle
          disabled={restrictedEdit}
          variant="secondary"
          className="mx-1"
          size="sm"
        >
          ...
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {(user.role === 'admin') && (
          <Dropdown.Item
            active={answeredDataShowed}
            onClick={() => {
              showAnswerData(index);
            }}
          >
            Data (
            {answerData}
            )
          </Dropdown.Item>
          )}
          <Dropdown.Divider />

          {isMemoryBankEnabled(user) && isSlotRequirementVisible && (
            <>
              <Dropdown.Item
                active={isSlotRequirementActive}
                onClick={() => {
                  showSlotRequirement(index);
                }}
              >
                Requirement (
                {slotRequirement}
                )
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}

          <DeleteStep
            deleteFunc={deleteAnswer}
            index={index}
            title="Delete Answer"
            content="Do you really want to delete this Answer?"
          />

        </Dropdown.Menu>
      </Dropdown>
    </Col>
  );
}
