import { User, UserAction } from '../../dorian-shared/types/user/User';

export function isMemoryBankEnabled(user: User) {
  if (user.role === 'admin') {
    return true;
  }
  if (user.actions.includes(UserAction.ChoiceMemory)) {
    return true;
  }
  return false;
}
