import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Badge, Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import imgAvatar from '../../../assets/images/avatar.png';
import { userApi } from '../../../services/userApi/UserApi';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { Deactivate } from '../Users/Deactivate';
import { AvatarImage } from './AvatarImage/AvatarImage';

const REACT_APP_SHOW_PAYOUT = process.env.REACT_APP_SHOW_PAYOUT !== undefined && process.env.REACT_APP_SHOW_PAYOUT && process.env.REACT_APP_SHOW_PAYOUT === 'true';

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingInfo: true,
      user: userApi.getProfileLegacy(),
      stats: {},
      validated: false,
      uploadFormLoading: false,
      uploadDone: false,
      uploadError: '',
      modalDeactivateShow: false,
      redirect: false,
    };
    this.btnSaveForm = React.createRef();
  }

  componentDidMount() {
    this.loadUserData();
    this.loadData();
  }

  loadUserData() {
    this.setState({
      loading: true,
    });

    api.get('/v1/user/profile?useronly=1')
      .then((res) => {
        const { user } = res.data;
        userApi.updateProfileLegacy(user);

        this.setState({
          user,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  loadData() {
    this.setState({
      loadingInfo: true,
    });
    api.get('/v1/user/profile')
      .then((res) => {
        const { user } = this.state;
        user.stories_count = res.data.user.stories_count;
        user.word_count = res.data.user.word_count;
        user.hearts = res.data.user.hearts;
        user.stories = res.data.user.stories;
        user.books = res.data.user.books;
        user.books_count = res.data.user.books_count;
        user.releasedEpisodes = res.data.user.releasedEpisodes;
        user.newReleasedEpisodes = res.data.user.newReleasedEpisodes;

        this.setState({
          user,
          stats: res.data.user.stats,
          loadingInfo: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingInfo: false,
        });
      });
  }

  saveUser(userData, validated) {
    if (validated === true) {
      this.setState({
        loading: true,
      });
      userApi.putProfile(userData)
        .then(() => {
          this.setState({
            loading: false,
            uploadDone: 'Update profile Successful',
          }, () => {
            setTimeout(() => {
              this.setState({
                uploadDone: false,
              });
            }, 2500);
          });
        })
        .catch((saveUserError) => {
          this.setState({
            loading: false,
            uploadError: saveUserError.response.data.error,
          }, () => {
            setTimeout(() => {
              this.setState({
                uploadError: '',
              });
            }, 2500);
          });
        });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.saveUser(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  modalDeactivateClose = (state) => {
    this.setState({ modalDeactivateShow: false });
    if (state) {
      this.setState({
        redirect: '/logout',
      });
    }
  };

  render() {
    const { history } = this.props;
    const {
      validated,
      user,
      loading,
      modalDeactivateShow,
      loadingInfo,
      uploadFormLoading,
      redirect,
      stats,
      uploadDone,
      uploadError,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    const listStories = () => {
      const books = user.books ? user.books : [];
      return books.map((book) => (
        <Alert
          key={book.id}
          variant="secondary"
          className="p-0 my-1"
        >
          <Alert.Link
            href={`/book/${book.id}`}
            className="px-3 py-1 d-block"
          >
            {book.title}
            {book.isPrompt && <Badge variant="secondary" style={{ marginLeft: '.5em' }}>Prompt</Badge>}
          </Alert.Link>
        </Alert>
      ));
    };

    const page = {
      header: {
        title: 'Profile',
        type: 'Profile',
        settings: user.role === 'admin' ? 'admin' : null,
      },
      sidebar: {
        nav: [
          {
            title: 'Back',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          (false && REACT_APP_SHOW_PAYOUT) && {
            title: 'Payout',
            href: '/payout',
            action: null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };

    const handleResetButton = () => {
      const profile = userApi.getProfile();
      if (profile?.login_provider === 'LEGACY') {
        history.push(`/sign-up?type=update&username=${encodeURIComponent(profile.email)}`);
        return;
      }
      history.push('/reset-password');
    };

    return (
      <PageWrapper
        {...this.props}
        page={page}
      >
        <>
          <Row>
            <Col xs={12} sm={6} className="text-center">
              <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
                <AvatarImage
                  imgURL={user.image?.imageUrl || imgAvatar}
                  userId={user.id}
                  onUpdate={() => this.loadUserData()}
                  isActionsVisible
                  rounded
                />
              </div>
              <Spinner
                variant="primary"
                animation="border"
                className={uploadFormLoading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
              />
            </Col>
            <Col xs={12} sm={6}>
              <div className="pt-2">
                {!loadingInfo
                  ? (user.hearts && user.hearts.totalHearts > 0) && (
                    (user.hearts && user.hearts.totalHearts > 0) && (
                    <>
                      <h4 className="text-center">Earnings</h4>
                      <p>
                        <b>
                          Estimated Hearts Earned:
                          {user.hearts ? user.hearts.estimatedHeartsEarned : 0}
                          {' '}
                          <span
                            role="img"
                            aria-label="heart"
                          >
                            ❤️
                          </span>
                        </b>
                      </p>
                      <p>
                        <b>
                          Hearts in Review:
                          {user.hearts ? user.hearts.heartsPayable : 0}
                          {' '}
                          <span
                            role="img"
                            aria-label="heart"
                          >
                            ❤️
                          </span>
                        </b>
                      </p>
                      <p>
                        <b>
                          Hearts Pending Payment:
                          {user.hearts ? user.hearts.heartsPending : 0}
                          {' '}
                          <span
                            role="img"
                            aria-label="heart"
                          >
                            ❤️
                          </span>
                        </b>
                      </p>
                      <p>
                        <b>
                          Hearts Paid Out - Lifetime:
                          {user.hearts ? user.hearts.heartsPaid : 0}
                          {' '}
                          <span
                            role="img"
                            aria-label="heart"
                          >
                            ❤️
                          </span>
                        </b>
                      </p>
                      <i>
                        <p>Dorian splits each dollar spent in your stories 50/50!</p>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        {/* <p>That's approximately 6 cents per heart.</p> */}
                        <p>You can cash out when you reach a threshold of 500 hearts</p>
                      </i>
                    </>
                    )
                  )
                  : (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="justify-content-center"
                      />
                    </div>
                  )}
              </div>
            </Col>
          </Row>

          <Row className="pt-4 pb-4">
            <Col xs={12} sm={6}>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <h6 className="text-center">First Name & Last Name</h6>
                <Form.Row>
                  <Form.Group as={Col} controlId="firstName" className="my-0 pt-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="First Name"
                      defaultValue={user.firstName}
                      name="firstName"
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose First Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="lastName" className="my-0 pt-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last Name"
                      defaultValue={user.lastName}
                      name="lastName"
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose Last Name.
                    </Form.Control.Feedback>
                  </Form.Group>

                </Form.Row>

                <h6 className="text-center pt-3 pb-1">Username</h6>
                <Form.Group controlId="username">
                  <Form.Control
                    type="text"
                    pattern="^([A-Za-z]|[0-9]|_|-|.)+$"
                    defaultValue={user.username || ''}
                    name="username"
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </Form.Group>

                <h4 className="text-center mt-4">About the Author</h4>

                <Form.Group controlId="about">
                  <Form.Control
                    placeholder="About the Author"
                    as="textarea"
                    defaultValue={user.about}
                    name="about"
                    onMouseDown={(e) => e.stopPropagation()}
                    rows="10"
                  />
                </Form.Group>

                <Button
                  type="button"
                  variant="info"
                  onClick={handleResetButton}
                >
                  Reset password
                </Button>

                <Alert show={!!uploadDone} variant="success" className="my-1">
                  {uploadDone || null}
                </Alert>

                <Alert show={!!uploadError} variant="danger" className="my-1">
                  {uploadError || null}
                </Alert>

                <div className="text-right">
                  <Button
                    type="submit"
                    ref={this.btnSaveForm}
                    variant="primary"
                    disabled={(!!loading)}
                  >
                    {loading && (
                    <Spinner
                      size="sm"
                      animation="border"
                    />
                    )}
                    Save
                  </Button>
                </div>
              </Form>
            </Col>
            <Col xs={12} sm={6}>
              <h4 className="text-center">Stories</h4>
              <div className="pt-2 listChaptersBox listChaptersBox-page">
                <div className="py-4">
                  <p>
                    Total Released Episodes:
                    {user.releasedEpisodes ? user.releasedEpisodes : 0}
                  </p>
                  <p>
                    New Episodes In The Last 30 Days:
                    {user.newReleasedEpisodes ? user.newReleasedEpisodes : 0}
                  </p>
                  <p>
                    Single Player Reads:
                    {stats?.total_reads ?? 0}
                  </p>
                </div>
                {!loadingInfo
                  ? listStories()
                  : (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="justify-content-center"
                      />
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </>
        {modalDeactivateShow && (
          <Deactivate
            show={modalDeactivateShow}
            onHide={this.modalDeactivateClose}
          />
        )}
      </PageWrapper>
    );
  }
}
