import { MemoryBank } from '../../../BookEconomyPage/types/bookEconomy';

export const SCROLLABLE_STEP_FORM_ELEMENT_ID = 'step_form_scrollable_element';

export function count(array: unknown[]): number {
  let c = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] !== 'undefined') {
      c += 1;
    }
  }
  return c;
}

export const checkUsedMemoriesInText = (text: string, memories: MemoryBank[]): string[] => {
  const matches = text.matchAll(/\{([^}]*)\}/g);
  const errors: string[] = [];
  for (const match of matches) {
    const memoryName = match[1];
    const memorySlot = memories.find((memory) => memory.name === memoryName);
    if (!memorySlot) {
      errors.push(memoryName);
    }
  }
  return errors;
};
