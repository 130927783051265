export enum StepTypeEconomy {
    Dialogue = 'Dialogue',
    Narrator = 'Narrator',
    Choice = 'Choice',
    Ending = 'Ending',
    Result = 'Result',
    PlayerSelect = 'PlayerSelect',
    Texting = 'Texting',
    Thinking = 'Thinking',
    Reaction = 'Reaction',
    Check = 'Check',
    Remember = 'Remember',
}

export type BookVariableValue = boolean | string | number;

export type GotoBranchEconomy = {
    branch: string;
}

export type StepAnswerEconomy = {
    type: string;
    text: string;
    goto: GotoBranchEconomy;
    requirement?: {
        check: StepCheckEconomy;
        visualTreatmentIfNotMet: 'Disable' | 'Hide';
    }
}

export type StepCheckOperator = 'equal' | 'notEqual' | 'greater' | 'atLeast' | 'less' | 'atMost' | 'min' | 'max';
export type StepCheckEconomy = {
    operator: StepCheckOperator;
    value: BookVariableValue;
    variable: string;
}

export type StepSwitchEconomy = {
    value: BookVariableValue;
    goto: GotoBranchEconomy;
}

export type StepActionType = 'set' | 'increase' | 'decrease';
export type StepActionEconomy = {
    type: StepActionType;
    value: BookVariableValue;
    variable: string;
}

export type StepEconomy = {
    index: number;
    text: string;
    type: StepTypeEconomy;
    goto?: GotoBranchEconomy;
    check?: StepCheckEconomy;
    answers?: StepAnswerEconomy[];
    switch?: StepSwitchEconomy[];
    action?: StepActionEconomy;
}

export type BranchEconomy = {
    id: string;
    name: string;
    steps: StepEconomy[];
}

export type BranchesEconomy = {
    [key: string]: BranchEconomy;
}

export type StoryEconomy = {
    id: number;
    chapter: number;
    title: string;
    revision: number;
    uuid: string;
    contents: {
        branches: BranchesEconomy;
    }
}

export type BookVariable = {
    defaultChangeDescription: string;
    defaultValue: BookVariableValue;
    displayName: string;
    icon: string | null;
    showIn: string[];
    type: 'number' | 'boolean' | 'string' | 'character';
}

export type BookEconomy = {
    id: number;
    title: string,
    uuid: string,
    stories: StoryEconomy[],
    variables: Record<string, BookVariable>;
}

export type MemoryBank = Record<string, BookVariableValue>;
