import React from 'react';
import { Form } from 'react-bootstrap';
import { MemoryShowIn } from '../memoryBankTypes';
import classes from './MemoryField.module.scss';

export function MemoryViewFiled(props: React.ComponentProps<typeof Form.Control>) {
  const {
    id,
    errorMessage,
    disabled,
    ...rest
  } = props;

  return (
    <Form.Group className="position-relative">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Default view
      </Form.Label>

      {disabled
        ? '-'
        : (
          <Form.Control
            as="select"
            id={id}
            {...rest}
          >
            <option value={MemoryShowIn.Hide} title="Don't show anywhere">Hide</option>
            <option value={MemoryShowIn.Alert} title="Popup alert when changed">Alert</option>
            <option value={MemoryShowIn.Panel} title="Popup alert & My Journey Panel">Panel</option>
            <option value={MemoryShowIn.Hud} title="Popup alert & My Journey Panel & Icon in Story">Panel & Story</option>
          </Form.Control>
        )}
      <Form.Control.Feedback type="invalid" tooltip>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
