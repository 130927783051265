import {
  array, boolean, number, object, string,
} from 'yup';
import { MemoryShowIn, MemoryType } from './memoryBankTypes';

export const memoryBankFormValidationSchema = object({
  memoryBankSlots: array().of(
    object({
      name: string()
        .min(3, 'Min 3 chars')
        .max(19, 'Max 19 chars')
        .required('Required')
        .matches(/^(?:host\.)?([_A-Za-z][_A-Za-z0-9]*)$/, { message: 'Invalid name' }),
      value: string()
        .when('type', (type) => {
          switch (type) {
            case MemoryType.Boolean:
              return boolean().required('Required');
            case MemoryType.String:
              return string().required('Required')
                .min(2, 'Min 2 chars')
                .max(50, 'Max 50 chars')
                .matches(/^([A-Za-z]|[0-9]|_|-| |[.])+$/, { message: 'Invalid value' });
            case MemoryType.Number:
              return number().required('Required');
            case MemoryType.Character:
              return number().required('Required');
            default:
              return string().required('Required');
          }
        }),
      type: string(),
      showIn: array().of(string()),
      displayName: string()
        .when('showIn', {
          is: (showIn: MemoryShowIn) => showIn && (showIn.includes(MemoryShowIn.Alert) || showIn.includes(MemoryShowIn.Panel)),
          then: string()
            .max(36, 'Max 36 chars')
            .required('Required'),
        }),
      icon: string()
        .when('showIn', {
          is: (showIn: MemoryShowIn) => showIn && (showIn.includes(MemoryShowIn.Alert) || showIn.includes(MemoryShowIn.Panel)),
          then: string().required('Required'),
        }),
      defaultChangeDescription: string()
        .when('showIn', {
          is: (showIn: MemoryShowIn) => showIn && (showIn.includes(MemoryShowIn.Alert) || showIn.includes(MemoryShowIn.Panel)),
          then: () => string()
            .max(128, 'Max 128 chars')
            .required('Required'),
        }),
    }),
  ),
});
