import React, { ChangeEvent } from 'react';
import { BookEconomy } from '../types/bookEconomy';

type BookEconomyFileLoaderProps = {
  onLoad?: (bookEconomy: BookEconomy) => void;
}

export function BookEconomyFileLoader(props: BookEconomyFileLoaderProps) {
  const { onLoad } = props;
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target?.result as string ?? '');
          onLoad?.(data);
        } catch (e) {
          console.error(e);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
    </div>
  );
}
